import React, { createContext, useState, useEffect } from 'react';
import { isBrowser } from '../components/global-helpers';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [participants, setParticipants] = useState([]);
  const [participant, setParticipant] = useState({});
  const [participantFormErrors, setParticipantFormErrors] = useState([]);
  const [toastMessage, setToastMessage] = useState({
    status: null,
    message: null,
  });
  const [activeLanguage, setActiveLanguage] = useState('nl');
  const [pages, setPages] = useState({});
  const [routes, setRoutes] = useState([]);
  const [switchedLanguage, setSwitchedLanguage] = useState(false);
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    if (isBrowser) {
      const savedParticipants = localStorage.getItem('participants');
      const savedLanguage = localStorage.getItem('lang');

      if (savedParticipants) {
        setParticipants(JSON.parse(localStorage.getItem('participants')));
      }

      if (savedLanguage) {
        setActiveLanguage(JSON.parse(savedLanguage));
      }
    }
  }, []);

  const createParticipant = () => {
    setParticipants((current) => [...current, participant]);
    participant.id = Date.now();
    participant._key = `incentive_24-${Date.now()}`;
    setParticipant({});
  };

  const deleteParticipant = (id) => {
    setParticipants(() => {
      const filteredParticipants = participants.filter(
        (participant) => participant.id !== id
      );

      if (isBrowser) {
        filteredParticipants.length
          ? localStorage.setItem(
              'participants',
              JSON.stringify(filteredParticipants)
            )
          : localStorage.removeItem('participants');
      }

      return filteredParticipants;
    });
  };

  const setParticipantValue = (key, value) => {
    setParticipant({ ...participant, [key]: value });
  };

  const setLanguage = (lang) => {
    if (isBrowser) {
      localStorage.setItem('lang', JSON.stringify(lang));
    }

    setActiveLanguage(lang);
  };

  return (
    <AppContext.Provider
      value={{
        participants,
        createParticipant,
        deleteParticipant,
        setParticipantValue,
        toastMessage,
        setToastMessage,
        setParticipants,
        participantFormErrors,
        setParticipantFormErrors,
        participant,
        activeLanguage,
        setLanguage,
        pages,
        setPages,
        routes,
        setRoutes,
        setSwitchedLanguage,
        switchedLanguage,
        setDocumentData,
        documentData
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
