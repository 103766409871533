import client from '../sanity-client';
import imageUrlBuilder from '@sanity/image-url';

export const uniqueId = () => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substr(2);

  return dateString + randomness;
};

export const errorToastMessage = () => ({
  status: 'danger',
  message: 'Er ging iets verkeerd, probeer het nog eens!',
});

export const successToastMessage = () => ({
  status: 'success',
  message: 'Het formulier is verzonden!',
});

export const isBrowser = typeof window !== 'undefined';

export const getFormData = (form) => {
  let formData = {};

  for (let input of form) {
    const { value, name } = input;

    if (value && input.type !== 'checkbox') {
      formData = { ...formData, [name]: value };
    }
  }

  return formData;
};

export const getImageUrl = (source) => {
  const builder = imageUrlBuilder(client);
  const urlFor = (source) => builder.image(source).url();

  return urlFor(source);
};
